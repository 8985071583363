/* BEGIN: Set domain-specific values */
// Subdomain override styles
import "@css/courses-swingu/var.css";
// Path to subdomain layout file
import Layout from "@global/layout/swingu-com/layout-default.vue";
// `js/components/${vueDir}/`
let vueDir = "courses-swingu";
// Accessible in Vue via `this.$constants`
const subdomainConstants = {

};
/* END: Set domain-specific values */

/**
 * SETUP NOTE: Subdomain must also be added to vite.config.js
 */

import { init } from "./main";
init(vueDir, Layout, subdomainConstants);
